import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../Context';
import { useEffect, useState } from 'react';
import { useGroups } from '../../services';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { formatNumber } from '../../Utils';
import { Button } from '../../components/shared/FormElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const OperatorReport: React.FC = () => {
  const { id } = useParams();
  const { apiCall } = useAppContext();
  const navigate = useNavigate();
  const { isAdministrador } = useGroups(['Administrador']);
  const [dateQuery, setDateQuery] = useState<string>(
    new Date()
      .toLocaleString('es-CO', {
        timeZone: 'America/Bogota', // UTC -5
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split('/')
      .reverse()
      .join('-')
  );
  const [report, setReport] = useState<any>({});

  useEffect(
    () => {
      if (!isAdministrador) {
        navigate('/');
        return;
      }
      getCurrentReport();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getCurrentReport = async () => {
    const response = await apiCall('GET', `report/operator/${id}/`, null, '', 'Error al obtener el reporte');
    if (response) {
      setReport(response);
    }
  };

  const getMonthReport = async () => {
    const response = await apiCall(
      'GET',
      `report/operator/${id}/${dateQuery}/`,
      null,
      '',
      'Error al obtener el reporte por fecha'
    );
    if (response) {
      setReport(response);
    }
  };

  const getByDate = async (value: any) => {
    setDateQuery(value);
    getMonthReport();
  };

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className='text-left'>
          <h2 className='text-left'>Reporte de Ventas - {report?.seller_name}</h2>
        </Grid>
        <Grid item xs={12} sm={2} className='text-left'>
          <Button variant='contained' color='primary' onClick={() => navigate(-1)}>
            Volver a Operadores
          </Button>
        </Grid>
        <Grid item xs={12} sm={2} className='text-left'>
          <Button variant='contained' color='primary' onClick={() => getCurrentReport()}>
            Informe actual
          </Button>
        </Grid>
        <Grid item xs={12} sm={2} className='text-right'>
          <input
            type='date'
            value={dateQuery}
            onChange={(e) => getByDate(e.target.value)}
            className='form-control date-audit'
          />
        </Grid>
      </Grid>
      {report ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant='h5' component='h2' mb={3}>
                  Enviados a producción
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de órdenes: {report.count_orders_sent_to_production}
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de prendas: {report.count_clothing_sent_to_production}
                </Typography>
                <Typography color='textSecondary'>
                  Valor total: ${formatNumber(report.total_amount_sent_to_production)}
                </Typography>
                {report.ids_sent_to_production?.length > 0 && (
                  <Grid mt={5}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography component='span'>Ordenes</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {report.ids_sent_to_production?.map((id: number) => (
                          <a
                            href={`/orden/${id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none' }}
                          >
                            <Chip label={id} style={{ margin: 5 }} />
                          </a>
                        ))}{' '}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant='h5' component='h2' mb={3}>
                  En proceso sin bodega
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de órdenes: {report.count_orders_in_process_not_bodega}
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de prendas: {report.count_clothing_in_process_not_bodega}
                </Typography>
                <Typography color='textSecondary'>
                  Valor total: ${formatNumber(report.total_amount_in_process_not_bodega)}
                </Typography>
                {report.ids_in_process_not_bodega?.length > 0 && (
                  <Grid mt={5}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography component='span'>Ordenes</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {report.ids_in_process_not_bodega?.map((id: number) => (
                          <a
                            href={`/orden/${id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none' }}
                          >
                            <Chip label={id} style={{ margin: 5 }} />
                          </a>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant='h5' component='h2' mb={3}>
                  En bodega
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de órdenes: {report.count_orders_in_process_bodega}
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de prendas: {report.count_clothing_in_process_bodega}
                </Typography>
                <Typography color='textSecondary'>
                  Valor total: ${formatNumber(report.total_amount_in_process_bodega)}
                </Typography>
                {report.ids_in_process_bodega?.length > 0 && (
                  <Grid mt={5}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography component='span'>Ordenes</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {report.ids_in_process_bodega?.map((id: number) => (
                          <a
                            href={`/orden/${id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none' }}
                          >
                            <Chip label={id} style={{ margin: 5 }} />
                          </a>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant='h5' component='h2' mb={3}>
                  Finalizados
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de órdenes: {report.count_orders_with_status_finalizado}
                </Typography>
                <Typography color='textSecondary'>
                  Cantidad de prendas: {report.count_clothing_with_status_finalizado}
                </Typography>
                <Typography color='textSecondary'>
                  Valor total: ${formatNumber(report.total_amount_with_status_finalizado)}
                </Typography>
                {report.ids_with_status_finalizado?.length > 0 && (
                  <Grid mt={5}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                      >
                        <Typography component='span'>Ordenes</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {report.ids_with_status_finalizado?.map((id: number) => (
                          <a
                            href={`/orden/${id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none' }}
                          >
                            <Chip label={id} style={{ margin: 5 }} />
                          </a>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default OperatorReport;
