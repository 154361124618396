import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faEye,
  faEyeSlash,
  faUsersViewfinder,
  faUsersCog,
  faUserPlus,
  faChartBar,
  faCog,
  faFlagCheckered,
  faCogs,
  faTrash,
  faCheckCircle,
  faDollar,
  faBoxArchive,
  faCaretDown,
  faCaretRight,
  faBrush,
  faCalendar,
  faCubesStacked,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';

import logo from '../assets/images/logo-horizontal.png';
import logosimbolo from '../assets/images/logo-simbolo.png';

import { useGroups, usePermissions } from '../services';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';

const Sidebar: React.FC = () => {
  const { canView_orders, canView_process, canView_user, canView_sketchrequests, canView_stock } = usePermissions([
    'view_orders',
    'view_process',
    'view_user',
    'view_sketchrequests',
    'view_stock',
  ]);
  const { isAdministrador, isJefedeVentas, isValidador, isJefedeProduccion, isVendedor, isAuxiliarContable } =
    useGroups(['Administrador', 'Jefe de Ventas', 'Validador', 'Jefe de Produccion', 'Vendedor', 'Auxiliar Contable']);

  const canView_stats = isAdministrador || isJefedeVentas || isJefedeProduccion;
  const canView_transactions = isAdministrador || isAuxiliarContable || isValidador || isJefedeVentas || isVendedor;
  const ableToViewProcess = canView_process ? true : !(isValidador || isVendedor);

  const { logout, setOpen, open } = useAppContext();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const [isOrdersOpen, setOrdersOpen] = useState(false); // Estado para controlar el desplegable "Órdenes"
  const [isTransactionsOpen, setTransactionsOpen] = useState(false); // Estado para controlar el desplegable "Movimientos"
  const [isSketchsOpen, setSketchsOpen] = useState(false); // Estado para controlar el desplegable "Bocetos"

  return (
    <SidebarContainer className={open ? 'open' : 'close'}>
      <HiddenButton className={open ? 'open' : 'close'} onClick={() => setOpen(!open)}>
        <img src={logosimbolo} className={open ? 'open' : 'close'} alt='logo' />
        <FontAwesomeIcon icon={open ? faEyeSlash : faEye} />
      </HiddenButton>
      {open ? (
        <>
          <div className='sidebar-header'>
            <img src={logo} alt='logo' />
          </div>
          <div className='sidebar-body' style={{ overflowY: 'auto' }}>
            {/* Grupo de Órdenes */}
            {canView_orders && (
              <>
                <SidebarItem
                  onClick={() => {
                    setTransactionsOpen(false);
                    setOrdersOpen(!isOrdersOpen);
                  }}
                >
                  <div className='sidebar-item-icon'>
                    <FontAwesomeIcon icon={isOrdersOpen ? faCaretDown : faCaretRight} />
                  </div>
                  <div className='sidebar-item-text'>Órdenes</div>
                </SidebarItem>
                {isOrdersOpen && (
                  <DropdownContainer>
                    <>
                      <SidebarItem onClick={() => handleNavigate('ordenes/')}>
                        <FontAwesomeIcon icon={faUsersViewfinder} />
                        <span>Órdenes Creadas</span>
                      </SidebarItem>
                      <SidebarItem onClick={() => handleNavigate('ordenes/por-validar')}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span>Órdenes Por Validar</span>
                      </SidebarItem>
                      <SidebarItem onClick={() => handleNavigate('ordenes/proceso')}>
                        <FontAwesomeIcon icon={faCogs} />
                        <span>Órdenes en Proceso</span>
                      </SidebarItem>
                      <SidebarItem onClick={() => handleNavigate('ordenes/finalizadas')}>
                        <FontAwesomeIcon icon={faFlagCheckered} />
                        <span>Órdenes Finalizadas</span>
                      </SidebarItem>
                      <SidebarItem onClick={() => handleNavigate('ordenes/bodega')}>
                        <FontAwesomeIcon icon={faBoxArchive} />
                        <span>Bodega</span>
                      </SidebarItem>
                    </>
                    {isAdministrador && (
                      <SidebarItem onClick={() => handleNavigate('ordenes/eliminadas')}>
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Órdenes Eliminadas</span>
                      </SidebarItem>
                    )}
                  </DropdownContainer>
                )}
              </>
            )}
            {/* Grupo de Procesos */}

            {ableToViewProcess ? (
              <SidebarItem onClick={() => handleNavigate('procesos/')}>
                <div className='sidebar-item-icon'>
                  <FontAwesomeIcon icon={faUsersCog} />
                </div>
                <div className='sidebar-item-text'>Procesos</div>
              </SidebarItem>
            ) : null}
            {canView_user && (
              <SidebarItem onClick={() => handleNavigate('usuarios/')}>
                <FontAwesomeIcon icon={faUserPlus} />
                <span>Usuarios</span>
              </SidebarItem>
            )}
            {canView_stats && (
              <SidebarItem onClick={() => handleNavigate('estadisticas/')}>
                <FontAwesomeIcon icon={faChartBar} />
                <span>Estadísticas</span>
              </SidebarItem>
            )}
            {canView_transactions && (
              <SidebarItem onClick={() => handleNavigate('agenda/')}>
                <FontAwesomeIcon icon={faCalendar} />
                <span>Agenda</span>
              </SidebarItem>
            )}
            {canView_sketchrequests && (
              <>
                <SidebarItem
                  onClick={() => {
                    setOrdersOpen(false);
                    setSketchsOpen(!isSketchsOpen);
                  }}
                >
                  <div className='sidebar-item-icon'>
                    <FontAwesomeIcon icon={isSketchsOpen ? faCaretDown : faCaretRight} />
                  </div>
                  <div className='sidebar-item-text'>Bocetos</div>
                </SidebarItem>
                {isSketchsOpen && (
                  <DropdownContainer>
                    <>
                      <SidebarItem onClick={() => handleNavigate('bocetos/')}>
                        <FontAwesomeIcon icon={faBrush} />
                        <span>Bocetos</span>
                      </SidebarItem>
                      <SidebarItem onClick={() => handleNavigate('bocetos/aprobados')}>
                        <FontAwesomeIcon icon={faBrush} />
                        <span>Bocetos Aprobados</span>
                      </SidebarItem>
                    </>
                  </DropdownContainer>
                )}
              </>
            )}
            {/* Grupo de Movimientos */}
            {canView_transactions && (
              <>
                <SidebarItem
                  onClick={() => {
                    setOrdersOpen(false);
                    setTransactionsOpen(!isTransactionsOpen);
                  }}
                >
                  <div className='sidebar-item-icon'>
                    <FontAwesomeIcon icon={isTransactionsOpen ? faCaretDown : faCaretRight} />
                  </div>
                  <div className='sidebar-item-text'>Movimientos</div>
                </SidebarItem>
                {isTransactionsOpen && (
                  <DropdownContainer>
                    <>
                      <SidebarItem onClick={() => handleNavigate('/movimientos/')}>
                        <FontAwesomeIcon icon={faDollar} />
                        <span>Movimientos Sin Asignar</span>
                      </SidebarItem>
                      <SidebarItem onClick={() => handleNavigate('/movimientos-asignados/')}>
                        <div className='sidebar-item-icon'>
                          <FontAwesomeIcon icon={faDollar} />
                        </div>
                        <div className='sidebar-item-text'>Movimientos Asignados</div>
                      </SidebarItem>
                    </>
                  </DropdownContainer>
                )}
              </>
            )}
            {canView_stock && (
              <SidebarItem onClick={() => handleNavigate('insumos/')}>
                <FontAwesomeIcon icon={faCubesStacked} />
                <span>Suministros</span>
              </SidebarItem>
            )}
            {isAdministrador && (
              <SidebarItem onClick={() => handleNavigate('auditoria/')}>
                <FontAwesomeIcon icon={faListCheck} />
                <span>Auditoria</span>
              </SidebarItem>
            )}
            {isAdministrador && (
              <SidebarItem onClick={() => handleNavigate('informes/')}>
                <FontAwesomeIcon icon={faNewspaper} />
                <span>Informes</span>
              </SidebarItem>
            )}
          </div>
          {/* Footer */}
          {isAdministrador || isJefedeVentas ? (
            <div className='sidebar-footer'>
              <SidebarItem onClick={() => handleNavigate('configuraciones/')}>
                <div className='sidebar-item-icon'>
                  <FontAwesomeIcon icon={faCog} />
                </div>
                <div className='sidebar-item-text'>Configuraciones</div>
              </SidebarItem>
            </div>
          ) : null}
          <div className='sidebar-footer'>
            <SidebarItem onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Cerrar sesión</span>
            </SidebarItem>
          </div>
        </>
      ) : null}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  width: 240px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--blueLight);
  border-right: 1px solid #f1f1f1;
  transition: all 0.3s ease;
  position: fixed;
  left: 0;
  z-index: 999;
  .sidebar-header {
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
    img {
      width: 100%;
    }
  }
  .sidebar-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
  }
  .sidebar-footer {
    padding: 10px;
    border-top: 1px solid #f1f1f1;
  }
  &.open {
    left: 0;
  }
  &.close {
    left: -240px;
  }
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    color: var(--blueDark);
  }
  svg {
    margin-right: 10px;
  }
`;

const DropdownContainer = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`;

const HiddenButton = styled.div`
  position: absolute;
  background: #0b4a87;
  display: flex;
  left: 240px;
  border-radius: 0px 25px 25px 0px;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px #010101;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  &:hover {
    background-color: var(--blueDark);
  }
  &.open {
    right: 210px;
    width: 40px;
  }
  &.close {
    right: 10px;
    width: 80px;
  }
  img {
    width: 30px;
    margin-right: 5px;
  }
  img.open {
    display: none;
  }
  img.close {
    display: block;
  }
`;

export default memo(Sidebar);
