export const BaseURL = process.env.REACT_APP_API_URL as string;

type OptionsReqType = {
  method: string;
  headers: Headers;
  body?: string;
};

export const get = async (url: string, params: any) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }
  const queryParams = params ? '?' + new URLSearchParams(params).toString() : '';
  const requestUrl = `${BaseURL + url}${queryParams}`;

  return fetch(requestUrl, {
    method: 'GET',
    headers: headers,
  });
};

export const post = async (url: string, data: any, options?: { isFormData?: boolean }) => {
  const headers = new Headers();

  // Solo agregar 'Content-Type: application/json' si no es FormData
  if (!options?.isFormData) {
    headers.append('Content-Type', 'application/json');
  }

  // Agregar el token de autorización si está disponible y no es la ruta de login
  if (localStorage.getItem('token') && url !== 'login/') {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }

  // Configurar las opciones de la solicitud
  const optionsReq: RequestInit = {
    method: 'POST',
    headers: headers,
  };

  // Agregar el cuerpo de la solicitud si hay datos
  if (data) {
    optionsReq.body = options?.isFormData ? data : JSON.stringify(data);
  }

  // Realizar la solicitud
  return fetch(BaseURL + url, optionsReq);
};

export const put = async (url: string, data: any, options?: any) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (options) {
    if (options.isFormData) {
      headers.delete('Content-Type');
    }
  }

  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }

  let optionsReq: OptionsReqType = {
    method: 'PUT',
    headers: headers,
  };

  if (data) {
    optionsReq = {
      ...optionsReq,
      body: options?.isFormData ? data : JSON.stringify(data),
    };
  }

  return fetch(BaseURL + url, optionsReq);
};

export const patch = async (url: string, data: any, options?: any) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (options) {
    if (options.isFormData) {
      headers.delete('Content-Type');
    }
  }

  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }

  let optionsReq: OptionsReqType = {
    method: 'PATCH',
    headers: headers,
  };

  if (data) {
    optionsReq = {
      ...optionsReq,
      body: options?.isFormData ? data : JSON.stringify(data),
    };
  }

  return fetch(BaseURL + url, optionsReq);
};

export const del = async (url: string, params: any) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);
  }
  const queryParams = params ? '?' + new URLSearchParams(params).toString() : '';
  const requestUrl = `${BaseURL + url}${queryParams}`;

  return fetch(requestUrl, {
    method: 'DELETE',
    headers: headers,
  });
};
