import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../Context';
import { useGroups } from '../../services';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEye } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../Utils';

const MenuReport: React.FC = () => {
  const { apiCall } = useAppContext();
  const navigate = useNavigate();
  const { isAdministrador } = useGroups(['Administrador']);
  const [sellers, setSellers] = useState<any[]>([]);
  const [operators, setOperators] = useState<any[]>([]);
  const [selectedCard, setSelectedCard] = useState(0);
  const [consolidatedSalesProduction, setConsolidatedSalesProduction] = useState<any>({});
  const [consolidatedSalesBodega, setConsolidatedSalesBodega] = useState<any>({});
  const [consolidatedSalesFinished, setConsolidatedSalesFinished] = useState<any>({});
  const [consolidatedSalesProcess, setConsolidatedSalesProcess] = useState<any>({});
  const [process, setProcess] = useState<any[]>([]);

  const [consolidatedProcess, setConsolidatedProcess] = useState<any[]>([]);

  useEffect(() => {
    if (!isAdministrador) {
      navigate('/');
      return;
    }

    getSellers();
    getOperators();
    getConsolidatedSalesProduction();
    getConsolidatedSalesBodega();
    getConsolidatedSalesFinished();
    getConsolidatedSalesProcess();
    getAllProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSellers = async () => {
    const response = await apiCall('GET', 'users/group/vendedor/', null, '', 'Error al obtener los vendedores');
    if (response) {
      setSellers(response);
    }
  };

  const getOperators = async () => {
    const response = await apiCall('GET', 'users/group/produccion/', null, '', 'Error al obtener los operadores');
    if (response) {
      setOperators(response);
    }
  };

  const getConsolidatedSalesProduction = async () => {
    const response = await apiCall(
      'GET',
      'report/consolidated_sales/production/',
      null,
      '',
      'Error al obtener las ventas consolidadas'
    );
    if (response) {
      setConsolidatedSalesProduction(response);
    }
  };

  const getConsolidatedSalesBodega = async () => {
    const response = await apiCall(
      'GET',
      'report/consolidated_sales/bodega/',
      null,
      '',
      'Error al obtener las ventas consolidadas'
    );
    if (response) {
      setConsolidatedSalesBodega(response);
    }
  };

  const getConsolidatedSalesFinished = async () => {
    const response = await apiCall(
      'GET',
      'report/consolidated_sales/finished/',
      null,
      '',
      'Error al obtener las ventas consolidadas'
    );
    if (response) {
      setConsolidatedSalesFinished(response);
    }
  };

  const getConsolidatedSalesProcess = async () => {
    const response = await apiCall(
      'GET',
      'report/consolidated_sales/process/',
      null,
      '',
      'Error al obtener las ventas consolidadas'
    );
    if (response) {
      setConsolidatedSalesProcess(response);
    }
  };

  const getAllProcess = async () => {
    const response = await apiCall('GET', 'processes/', null, '', 'Error al obtener los procesos');
    if (response) {
      setProcess(response);
    }
  };

  useEffect(() => {
    if (process.length > 0) {
      const processIds = process.map((p) => p.id);
      const promises = processIds.map((id) => apiCall('GET', `report/consolidated_by_process/${id}/`, null, '', ''));
      Promise.all(promises).then((values) => {
        setConsolidatedProcess(values);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);

  console.log(consolidatedProcess);

  const cards = [
    {
      id: 1,
      title: 'Reporte de Ventas',
      description: 'Reporte de ventas por vendedor.',
    },
    {
      id: 2,
      title: 'Reporte de Procesos',
      description: 'Reporte de procesos por operador.',
    },
  ];

  return (
    <div className='container-wrap'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className='text-center'>
          <h2 className='text-left'>Informes</h2>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            gap: '1rem',
            marginTop: '1rem',
          }}
        >
          {cards.map((card, index) => (
            <Card key={card.id}>
              <CardActionArea
                onClick={() => setSelectedCard(index)}
                data-active={selectedCard === index ? '' : undefined}
                sx={{
                  height: '100%',
                  '&[data-active]': {
                    backgroundColor: '#18507e',
                    color: '#0d2538',
                    borderColor: 'white',
                    '&:hover': {
                      backgroundColor: 'action.selectedHover',
                    },
                  },
                }}
              >
                <CardContent sx={{ height: '100%' }}>
                  <Typography variant='h5' component='div'>
                    {card.title}
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {card.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>

        {selectedCard === 0 && (
          <>
            <Grid item xs={12} sm={6} className='text-left'>
              <Box>
                <h3 style={{ marginBottom: 5 }}>Vendedores</h3>
                <ul style={{ listStyle: 'none' }}>
                  {sellers.map((seller) => (
                    <li
                      key={seller.id}
                      style={{
                        cursor: 'pointer',
                        background: 'white',
                        borderBottom: '1px solid #ccc',
                        padding: '0.5rem 0.5rem',
                        color: '#666',
                        display: 'flex',
                        justifyContent: 'space-between',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                      onClick={() => navigate(`/informes/vendedor/${seller.id}`)}
                    >
                      {seller.username}

                      <FontAwesomeIcon icon={faEye} style={{ color: '#18507e' }} />
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className='text-left'>
              <h3 style={{ marginBottom: 5 }}>Reporte Consolidado</h3>
              {consolidatedSalesProduction && (
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h2' mb={3}>
                      Enviados a producción
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de órdenes: {consolidatedSalesProduction.count_orders_sent_to_production}
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de prendas: {consolidatedSalesProduction.count_clothing_sent_to_production}
                    </Typography>
                    <Typography color='textSecondary'>
                      Valor total: ${formatNumber(consolidatedSalesProduction.total_amount_sent_to_production)}
                    </Typography>
                    {consolidatedSalesProduction.ids_sent_to_production?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidatedSalesProduction.ids_sent_to_production?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              )}
              <Divider style={{ height: 10 }} />
              {consolidatedSalesProcess && (
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h2' mb={3}>
                      En proceso sin bodega
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de órdenes: {consolidatedSalesProcess.count_orders_in_process_not_bodega}
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de prendas: {consolidatedSalesProcess.count_clothing_in_process_not_bodega}
                    </Typography>
                    <Typography color='textSecondary'>
                      Valor total: ${formatNumber(consolidatedSalesProcess.total_amount_in_process_not_bodega)}
                    </Typography>
                    {consolidatedSalesProcess.ids_in_process_not_bodega?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidatedSalesProcess.ids_in_process_not_bodega?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              )}
              <Divider style={{ height: 10 }} />
              {consolidatedSalesBodega && (
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h2' mb={3}>
                      En bodega
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de órdenes: {consolidatedSalesBodega.count_orders_in_process_bodega}
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de prendas: {consolidatedSalesBodega.count_clothing_in_process_bodega}
                    </Typography>
                    <Typography color='textSecondary'>
                      Valor total: ${formatNumber(consolidatedSalesBodega.total_amount_in_process_bodega)}
                    </Typography>
                    {consolidatedSalesBodega.ids_in_process_bodega?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidatedSalesBodega.ids_in_process_bodega?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              )}
              <Divider style={{ height: 10 }} />
              {consolidatedSalesFinished && (
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h2' mb={3}>
                      Finalizados
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de órdenes: {consolidatedSalesFinished.count_orders_with_status_finalizado}
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de prendas: {consolidatedSalesFinished.count_clothing_with_status_finalizado}
                    </Typography>
                    <Typography color='textSecondary'>
                      Valor total: ${formatNumber(consolidatedSalesFinished.total_amount_with_status_finalizado)}
                    </Typography>
                    {consolidatedSalesFinished.ids_with_status_finalizado?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidatedSalesFinished.ids_with_status_finalizado?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              )}
            </Grid>
          </>
        )}
        {selectedCard === 1 && (
          <>
            <Grid item xs={12} sm={6} className='text-left'>
              <Box>
                <h3 style={{ marginBottom: 5 }}>Operadores</h3>
                <ul style={{ listStyle: 'none' }}>
                  {operators.map((operator) => (
                    <li
                      style={{
                        background: 'white',
                        borderBottom: '1px solid #ccc',
                        padding: '0.5rem 0.5rem',
                        color: '#666',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={operator.id}
                      onClick={() => navigate(`/informes/operador/${operator.id}`)}
                    >
                      <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{operator.username}</span>
                      {operator.process_roles?.length > 0
                        ? operator.process_roles.map((role: any) => role.name).join(', ')
                        : 'Sin roles'}
                      <FontAwesomeIcon icon={faEye} style={{ color: '#18507e' }} />
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className='text-left'>
              <h3 style={{ marginBottom: 5 }}>Reporte Consolidado</h3>
              {consolidatedProcess.map((consolidated: any, index: number) => (
                <Card key={index} style={{ marginBottom: 3 }}>
                  <CardContent>
                    <Typography variant='h5' component='h2' mb={3}>
                      {process[index].process_name}
                    </Typography>
                    <Typography color='textSecondary'>
                      Cantidad de prendas por iniciar: {consolidated?.processes?.pending?.count}
                    </Typography>
                    {consolidated?.processes?.pending?.ids?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidated?.processes?.pending?.ids?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}

                    <Divider style={{ height: 10 }} />

                    <Typography color='textSecondary'>
                      Cantidad de prendas en proceso: {consolidated?.processes?.processing?.count}
                    </Typography>
                    {consolidated?.processes?.processing?.ids?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidated?.processes?.processing?.ids?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}

                    <Divider style={{ height: 10 }} />

                    <Typography color='textSecondary'>
                      Cantidad de prendas finalizadas: {consolidated?.processes?.finished?.count}
                    </Typography>
                    {consolidated?.processes?.finished?.ids?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidated?.processes?.finished?.ids?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}

                    <Divider style={{ height: 10 }} />

                    <Typography color='textSecondary'>
                      Cantidad de prendas (Otros estados): {consolidated?.processes?.other?.count}
                    </Typography>
                    {consolidated?.processes?.other?.ids?.length > 0 && (
                      <Grid mt={5}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            <Typography component='span'>Ordenes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {consolidated?.processes?.other?.ids?.map((id: number) => (
                              <a
                                href={`/orden/${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <Chip label={id} style={{ margin: 5 }} />
                              </a>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default MenuReport;
