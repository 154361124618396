import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAppContext } from '../../Context';
import { setLoading, setDesignerProbabilities, DesignerProbabilitiesType } from '../../slices/miscSlice';
import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { Button } from '../../components/shared/FormElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

const ViewDesignersProbabilitiesTable: React.FC<{ hideActions: boolean }> = ({ hideActions }) => {
  const designerProbabilities = useAppSelector((state) => state.misc.designerProbabilities);

  const [editForm, setEditForm] = useState(false);
  const [currentDesignerProbability, setCurrentDesignerProbability] = useState({} as DesignerProbabilitiesType);
  const dispatch = useAppDispatch();
  const { apiCall } = useAppContext();

  const handleUpdateProbability = async () => {
    dispatch(setLoading(true));
    const response = await apiCall(
      'PUT',
      `designers-probabilities/${currentDesignerProbability.designer.id}/`,
      { probability: currentDesignerProbability.probability },
      'Probabilidad actualizada exitosamente',
      'Error al actualizar la probabilidad'
    );
    dispatch(setLoading(false));
    dispatch(setDesignerProbabilities(response));
    setCurrentDesignerProbability({} as DesignerProbabilitiesType);
    setEditForm(false);
  };

  const handleEditProbability = (designerProbability: DesignerProbabilitiesType) => {
    setCurrentDesignerProbability(designerProbability);
    setEditForm(true);
  };

  return (
    <>
      {editForm && currentDesignerProbability && (
        <div className='form-container' style={{ marginBottom: 10 }}>
          <h2>
            Editar Probabilidad para {currentDesignerProbability.designer.first_name}{' '}
            {currentDesignerProbability.designer.last_name}{' '}
          </h2>
          <div className='form-group'>
            <label>Probabilidad de Asignación</label>
            <TextField
              type='number'
              value={currentDesignerProbability.probability}
              onChange={(e) =>
                setCurrentDesignerProbability({
                  ...currentDesignerProbability,
                  probability: parseFloat(e.target.value),
                })
              }
              className='form-control'
            />
          </div>
          <Button className='btn-secondary' onClick={handleUpdateProbability} style={{ marginBottom: 5 }}>
            Actualizar
          </Button>
          <br />
          <Button className='btn-secondary' onClick={() => setEditForm(false)}>
            Cancelar
          </Button>
        </div>
      )}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item sm={12} className='table-responsive'>
          <TableContainer className='table-container'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>NOMBRE</b>
                  </TableCell>
                  <TableCell>
                    <b>PROBABILIDAD</b>
                  </TableCell>
                  <TableCell width={150}>
                    <b>ACCIONES</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {designerProbabilities?.map((designerProbability: DesignerProbabilitiesType) => (
                  <TableRow key={designerProbability.designer.id}>
                    <TableCell>
                      {designerProbability.designer.first_name} {designerProbability.designer.last_name}
                    </TableCell>
                    <TableCell>{designerProbability.probability ?? 100}</TableCell>
                    <TableCell>
                      {!hideActions && (
                        <Grid container spacing={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Button onClick={() => handleEditProbability(designerProbability)}>
                                <FontAwesomeIcon icon={faPencil} />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

const ViewDesignersProbabilities: React.FC = () => {
  const { apiCall } = useAppContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getDesignersProbabilities = async () => {
      dispatch(setLoading(true));
      const response = await apiCall(
        'GET',
        'designers-probabilities/',
        null,
        '',
        'Error al obtener las probabilidades de los diseñadores'
      );
      dispatch(setLoading(false));
      dispatch(setDesignerProbabilities(response));
    };
    getDesignersProbabilities();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h1>Probabilidades de los diseñadores</h1>
      <div className='container-wrap'>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} className='text-left'>
            <ViewDesignersProbabilitiesTable hideActions={false} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ViewDesignersProbabilities;
